import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LoadingStates } from '@nx/core/lib/interfaces/loading-states.interface';
import {
	Payment,
	PaymentStates,
} from '@nx/core/lib/interfaces/payment.interface';
import { DeceasedService } from 'provider-deceased/lib/deceased.service';
import { ButtonStyleTypes } from 'ui-button';
import { RouteNames } from '../../../app.routes';
import { PremiumModalService } from '../../../services/premium-modal/premium-modal.helper';

export const codeActivationId = 'code-geactiveerd';

@Component({
	selector: 'dashboard-payment-feedback',
	templateUrl: './payment-feedback.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class PaymentFeedbackPageComponent implements OnInit {
	@Input() deceasedId = '';
	@Input() paymentId = '';

	readonly codeActivationId = codeActivationId;
	readonly loadingStates = LoadingStates;
	readonly paymentStates = PaymentStates;
	readonly routeNames = RouteNames;
	readonly buttonStyleTypes = ButtonStyleTypes;

	paymentStateSig = this.deceasedService.paymentStateComputed;
	paymentStatus = PaymentStates.OPEN;

	constructor(
		private readonly deceasedService: DeceasedService,
		private readonly premiumModalService: PremiumModalService,
	) {}

	ngOnInit() {
		if (this.paymentId === codeActivationId) {
			this.paymentStatus = PaymentStates.CODE_ACTIVATED;
		} else {
			this.deceasedService
				.verifyPayment(this.deceasedId)
				.subscribe((response) => {
					this.handlePaymentVerification(response);
				});
		}
	}

	retry() {
		this.premiumModalService.isModalOpenSig.set(true);
	}

	private handlePaymentVerification(response: Payment | undefined) {
		if (response) {
			if (response.isPremium) {
				this.paymentStatus = PaymentStates.PAID;
			} else if (response.payment.status === PaymentStates.OPEN) {
				this.paymentStatus = PaymentStates.OPEN;
			} else {
				this.paymentStatus = PaymentStates.FAILED;
			}
		} else {
			this.paymentStatus = PaymentStates.FAILED;
		}
	}
}
