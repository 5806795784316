<ng-template #premiumSuccessTitleTemplate>
	<span
		[innerHTML]="
			'payment.premium-enabled-title' | translate | translateCut : 0
		"
	></span>
	<span
		class="text-yellow-600"
		[innerHTML]="
			'payment.premium-enabled-title' | translate | translateCut : 1
		"
	></span>
	<span
		[innerHTML]="
			'payment.premium-enabled-title' | translate | translateCut : 2
		"
	></span>
</ng-template>
<div class="flex h-full items-center justify-center p-3 sm:px-6">
	<div
		class="mx-auto flex max-w-sm flex-col gap-16 text-center"
		*ngIf="
			paymentId !== codeActivationId &&
				paymentStateSig().state === loadingStates.LOADING;
			else paymentResult
		"
	>
		<img
			width="100%"
			height="auto"
			[alt]="'payment.payment-check-illustration-alt' | translate"
			src="assets/images/payment-pending.svg"
		/>
		<div class="flex flex-col gap-6">
			<h1
				class="text-2xl font-bold text-purple-500"
				*ngIf="'payment.payment-check-title' | translate"
			>
				{{ 'payment.payment-check-title' | translate }}
			</h1>
			<p *ngIf="'payment.payment-check-text' | translate">
				{{ 'payment.payment-check-text' | translate }}
			</p>
			<div class="relative h-2 rounded-full bg-purple-50">
				<div
					class="absolute h-full w-1/3 animate-loading-bar rounded-full bg-purple-500"
				></div>
			</div>
		</div>
	</div>
	<ng-template #paymentResult>
		<div
			class="mx-auto flex max-w-sm flex-col gap-16 text-center"
			*ngIf="paymentStatus === paymentStates.CODE_ACTIVATED"
		>
			<img
				width="100%"
				height="auto"
				[alt]="'payment.premium-enabled-illustration-alt' | translate"
				src="assets/images/payment-confirmed.svg"
			/>
			<div class="flex flex-col gap-6">
				<h1 class="text-2xl font-bold text-purple-500">
					<ng-container
						*ngTemplateOutlet="premiumSuccessTitleTemplate"
					></ng-container>
				</h1>
				<p>
					{{ 'payment.activation-success-text' | translate }}
				</p>
				<ui-button
					[styleType]="buttonStyleTypes.PRIMARY"
					[routerLink]="['/' + routeNames.TASKS]"
					[replaceUrl]="true"
				>
					{{ 'payment.proceed-action' | translate }}
				</ui-button>
			</div>
		</div>
		<div
			class="mx-auto flex max-w-sm flex-col gap-16 text-center"
			*ngIf="paymentStatus === paymentStates.OPEN"
		>
			<img
				width="100%"
				height="auto"
				[alt]="'payment.payment-pending-illustration-alt' | translate"
				src="assets/images/payment-pending.svg"
			/>
			<div class="flex flex-col gap-6">
				<h1 class="text-2xl font-bold text-purple-500">
					{{ 'payment.payment-pending-title' | translate }}
				</h1>
				<p>
					{{ 'payment.payment-pending-text' | translate }}
				</p>
				<ui-button
					[styleType]="buttonStyleTypes.PRIMARY"
					[routerLink]="['/' + routeNames.TASKS]"
					[replaceUrl]="true"
				>
					{{ 'payment.proceed-action' | translate }}
				</ui-button>
			</div>
		</div>
		<div
			class="mx-auto flex max-w-sm flex-col gap-16 text-center"
			*ngIf="paymentStatus === paymentStates.PAID"
		>
			<img
				width="100%"
				height="auto"
				[alt]="'payment.premium-enabled-illustration-alt' | translate"
				src="assets/images/payment-confirmed.svg"
			/>
			<div class="flex flex-col gap-6">
				<h1 class="text-2xl font-bold text-purple-500">
					<ng-container
						*ngTemplateOutlet="premiumSuccessTitleTemplate"
					></ng-container>
				</h1>
				<p>
					{{ 'payment.payment-success-text' | translate }}
				</p>
				<ui-button
					[styleType]="buttonStyleTypes.PRIMARY"
					[routerLink]="['/' + routeNames.TASKS]"
					[replaceUrl]="true"
				>
					{{ 'payment.proceed-action' | translate }}
				</ui-button>
			</div>
		</div>
		<div
			class="mx-auto flex max-w-sm flex-col gap-16 text-center"
			*ngIf="paymentStatus === paymentStates.FAILED"
		>
			<img
				width="100%"
				height="auto"
				[alt]="'payment.payment-failed-illustration-alt' | translate"
				src="assets/images/payment-failed.svg"
			/>
			<div class="flex flex-col gap-6">
				<h1 class="text-2xl font-bold text-purple-500">
					{{ 'payment.payment-failed-title' | translate }}
				</h1>
				<p>
					{{ 'payment.payment-failed-text' | translate }}
				</p>
				<ui-button
					[styleType]="buttonStyleTypes.PRIMARY"
					(click)="retry()"
				>
					{{ 'payment.retry-action' | translate }}
				</ui-button>
			</div>
		</div>
	</ng-template>
</div>
